<template>
	<div>
		<b-container class="mt-sm-5 mt-3">
			<b-row>
				<b-col sm="12">
					<h2 class="text-center mb-5">
						<span class="w-color-black">Pod</span> <span class="z-fancy-underline w-color-orange">
							záštitou<svg-fancy-underline-icon />
							<img src="./img/rewards.png" class="a-small-logo" alt="">
						</span>
					</h2>

					<div class="logos-container d-flex flex-wrap justify-content-center align-items-center">
						<div
							v-for="partner of underTheAuspicesOfPartners" :key="partner.name"
							class="logo-container d-flex justify-content-center align-items-center"
						>
							<img :src="partner.imgLink" :alt="partner.name + ' - Logo'" class="logo" :class="partner.classes">
						</div>
					</div>

					<h2 class="text-center mb-5">
						<span class="w-color-secondary z-fancy-underline">
							Podporujú<svg-fancy-underline-icon />
							<img src="@/plugins/appzmudri/home/courses/img/top-courses.png" class="a-small-logo" alt="">
						</span> <span class="w-color-black">nás</span>
					</h2>

					<div class="logos-container d-flex flex-wrap justify-content-center align-items-center">
						<div
							v-for="partner of partners" :key="partner.name"
							class="logo-container d-flex justify-content-center align-items-center"
						>
							<img :src="partner.imgLink" :alt="partner.name + ' - Logo'" class="logo" :class="partner.classes">
						</div>
					</div>

					<!-- <h2 class="text-center mb-5">
						<span class="w-color-black">Partneri učiteľskej súťaže</span>
					</h2>

					<div class="logos-container d-flex flex-wrap justify-content-center align-items-center">
						<a
							v-for="partner of teacherCompetitionPartners" :key="partner.name"
							class="logo-container d-flex justify-content-center align-items-center"
							:href="partner.url" target="_blank" rel="noopener noreferrer"
						>
							<img :src="partner.imgLink" :alt="partner.name + ' - Logo'" class="logo" :class="partner.classes">
						</a>
					</div> -->
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>

export default {
	components: {
		'svg-fancy-underline-icon': () => import('@/plugins/appzmudri/_theme/icon/fancy-underline.svg?inline')
	},
	data() {
		return { 
			underTheAuspicesOfPartners: [
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635339438/online%20platform/Regions/01-ba_va56bl_x8ys1j.png',
					name: 'Bratislavský samosprávny kraj',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1606919868/online%20platform/Regions/logo_ke_prp5ud.png',
					name: 'Košický samosprávny kraj',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635339036/online%20platform/Regions/02NT_zm32ou_uccfug.jpg',
					name: 'Nitriansky samosprávny kraj',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1601963537/online%20platform/Regions/logo_tsk_ecsgel.svg',
					name: 'Trenčiansky samosprávny kraj',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1601962866/online%20platform/Regions/04TT_xfg2a1.jpg',
					name: 'Trnavský samosprávny kraj',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635339088/online%20platform/Regions/05ZA_s6oele_s2obot.png',
					name: 'Žilinský samosprávny kraj',
					classes: [],
				},

			],
			partners: [
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1607588518/online%20platform/Partner%20logos/msvvssr_dzzuqs.jpg',
					name: 'Ministerstvo školstva, vedy, výskumu a športu Slovenskej republiky',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635345565/online%20platform/Partner%20logos/iuventa-logo_brrtvu_ql9nue.png',
					name: 'IUVENTA',
					classes: ['logo-iuventa']
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635339603/online%20platform/Partner%20logos/mssr-logo_emruwl.png',
					name: 'Ministerstvo spravodlivosti Slovenskej republiky',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635339763/online%20platform/Partner%20logos/martinus-logo_v6pe68_txqlns.png',
					name: 'Martinus',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635339883/online%20platform/Partner%20logos/nadacia_orange_logo_ufl229_iuyj7a_xudu1y.jpg',
					name: 'Nadácia Orange',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1619638504/online%20platform/Partner%20logos/accenture_pqfnl3.png',
					name: 'Accenture',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635339960/online%20platform/Partner%20logos/ferova-nadacia-o2_s77b5t.png',
					name: 'Férová nadácia O2',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1624974850/online%20platform/Partner%20logos/nadacia-slovenskej-sporitelne_vfgbg5.png',
					name: 'Nadácia Slovenskej sporiteľne',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635340073/online%20platform/Partner%20logos/active-citizens-fund-acf-logo_pw1lcu.png',
					name: 'Active Citizens Fund',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635340127/online%20platform/Partner%20logos/nadacny-fond-telekom_zmfe04.png',
					name: 'Nadačný fond Telekom',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1602650410/online%20platform/Partner%20logos/us-embassy_kemiwb.png',
					name: 'Embassy of the United States of America',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1637834944/online%20platform/Partner%20logos/british-embassy-bratislava-2_pzth6b.jpg',
					name: 'Embassy of the United States of America',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635340241/online%20platform/Partner%20logos/fond-sk-nic-logo_ethsrj_preqps.png',
					name: 'British Embassy in Slovakia',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635340341/online%20platform/Partner%20logos/nadacia-pontis-logo_diwmjy_lqg5no.png',
					name: 'Nadácia Pontis',
					classes: [],
				},
				{
					imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1602756586/online%20platform/Partner%20logos/accacelife_logo_o6af5i.png',
					name: 'Accacelife',
					classes: [],
				},
				// {
				// 	imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635340410/online%20platform/Partner%20logos/lynx-logo_prmpm3.png',
				// 	name: 'Lynx',
				// 	classes: [],
				// }
			],
			// teacherCompetitionPartners: [
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635257248/online%20platform/Partner%20logos/goethe-institute-logo_p1c16c.png',
			// 		name: 'Goethe-Institut',
			// 		classes: [],
			// 		url: 'https://www.goethe.de/ins/sk/sk/index.html'
			// 	},
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635340713/online%20platform/Partner%20logos/curaprox-logo_uz268a.jpg',
			// 		name: 'Curaprox',
			// 		classes: [],
			// 		url: 'https://curaprox.sk/'
			// 	},
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635257595/online%20platform/Partner%20logos/salamandra-resort-hotel-logo_kv4l84.png',
			// 		name: 'Salamandra hotel resort',
			// 		classes: [],
			// 		url: 'https://salamandra.sk/'
			// 	},
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635258080/online%20platform/Partner%20logos/dna-era-logo_csfdtv.png',
			// 		name: 'DNA ERA',
			// 		classes: [],
			// 		url: 'https://dnaera.com/'
			// 	},
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635340840/online%20platform/Partner%20logos/nadacia-eset-logo_lyzqhd_j77lge.png',
			// 		name: 'Nadácia ESET',
			// 		classes: [],
			// 		url: 'https://www.nadaciaeset.sk/'
			// 	},
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1633334069/online%20platform/Partner%20logos/the-bridge-logo_xv4jvl.png',
			// 		name: 'The Bridge',
			// 		classes: [],
			// 		url: 'https://thebridge.sk/'
			// 	},
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635258442/online%20platform/Partner%20logos/lunys-logo_mbrkbq.png',
			// 		name: 'Lunys',
			// 		classes: [],
			// 		url: 'https://lunys.sk/'
			// 	},
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635341096/online%20platform/Partner%20logos/regiojet-logo_yepdnr.png',
			// 		name: 'RegioJet',
			// 		classes: [],
			// 		url: 'https://www.regiojet.sk/'
			// 	},
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635341154/online%20platform/Partner%20logos/publixing-logo_m947sk.jpg',
			// 		name: 'PUBLIXING',
			// 		classes: [],
			// 		url: 'https://www.publixing.com/'
			// 	},
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635341232/online%20platform/Partner%20logos/vydavatelstvo-tatran-logo_u8iu4f.png',
			// 		name: 'Vydavateľstvo TATRAN',
			// 		classes: [],
			// 		url: 'https://www.slovtatran.sk/'
			// 	},
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635341312/online%20platform/Partner%20logos/generacia-hladajuca-zmysel-logo_i44qgm.jpg',
			// 		name: 'Generácia hľadajúca zmysel',
			// 		classes: [],
			// 		url: 'http://www.expertnazivot.sk/'
			// 	},
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635341415/online%20platform/Partner%20logos/goriffee-logo_khafe6_xxyb9i.png',
			// 		name: 'Goriffee',
			// 		classes: [],
			// 		url: 'https://goriffee.com/'
			// 	},
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635341469/online%20platform/Partner%20logos/artforum-logo_fapawx_w6qb6d.png',
			// 		name: 'Artforum',
			// 		classes: [],
			// 		url: 'https://www.artforum.sk/'
			// 	},
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635341532/online%20platform/Partner%20logos/slovenske-narodne-muzeum-logo_rrxvoq_hwzoko.jpg',
			// 		name: 'Slovenské národné múzeum',
			// 		classes: [],
			// 		url: 'https://www.snm.sk/'
			// 	},
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635341596/online%20platform/Partner%20logos/it-learning-slovakia-logo_bqgwjg.png',
			// 		name: 'IT Learning Slovakia',
			// 		classes: [],
			// 		url: 'https://www.itlearning.sk/'
			// 	},
			// 	// {
			// 	// 	imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635341685/online%20platform/Partner%20logos/microsoft-silver-partner-logo_xe0ckb_u629fw.jpg',
			// 	// 	name: 'Silver Microsoft Partner',
			// 	// 	classes: [],
			// 	// 	url: ''
			// 	// },
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635341763/online%20platform/Partner%20logos/ihrysko-logo_jrg3xp_ymffis.jpg',
			// 		name: 'iHRYsko',
			// 		classes: [],
			// 		url: 'https://www.ihrysko.sk/'
			// 	},
			// 	{
			// 		imgLink: 'https://res.cloudinary.com/zmudri/image/upload/v1635341873/online%20platform/Partner%20logos/scribo-logo_wjtqum_acoyuz_f28qg1.png',
			// 		name: 'Scribo',
			// 		classes: ['logo-scribo'],
			// 		url: 'https://www.scribo.sk/'
			// 	}
			// ]
		}
	},
}
</script>

<style lang="scss" scoped>
$max-width: 170px;
$max-height: 100px;
$max-width-l: 200px;
$max-height-l: 130px;
$max-width-xl: 255px;
$max-height-xl: 150px;

.logos-container {
	row-gap: 20px;
	column-gap: 40px;
	margin-bottom: 50px;

	@media only screen and (min-width: 1200px) {
		row-gap: 40px;
		margin-bottom: 100px;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.logo-container {
	width: $max-width-xl;
	height: $max-height-xl;
}

.logo {
	width: auto;
	max-width: $max-width;
	height: auto;
	max-height: $max-height;

	@media only screen and (min-width: 1680px) {
		max-width: $max-width-l;
		max-height: $max-height-l;
	}
}

.logo-iuventa {
	height: 100px;
}

.logo-scribo {
	height: 120px;
}
</style>